<template>
  <v-dialog
      v-model="dialog"
      max-width="700"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="headline">
          {{`${edit?'Update':"Add"} device`}}
          <hr>
        </v-card-title>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon  @click="closeDialog">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field outlined dense v-model="user.first_name">
                <template v-slot:label>
                  Device name
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.user.first_name.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field outlined dense v-model="user.last_name">
                <template v-slot:label>
                  Location
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.user.last_name.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="!edit">
              <v-text-field outlined dense v-model="user.email">
                <template v-slot:label>
                  Email
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="!$v.user.email.email">Email must be valid</span><br>
              <span class="text-danger" v-if="$v.user.email.$error">This information is required</span>
            </v-col>

            <v-col cols="12">
              <v-select
                outlined
                dense
                v-model="user.device_category"
                :items="deviceCategories"
                item-text="name"
                item-value="value"
              >
                <template
                  v-slot:label
                >
                Device Category <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.user.device_category.$error">This information is required</span>
            </v-col>

            <v-col cols="12" v-if="user.device_category == 'attendance_general'">
              <v-text-field
                outlined
                dense
                v-model="user.ip_address"
                :error="$v.user.ip_address.$error"
              >
                <template
                  v-slot:label
                >
                  IP address<span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.user.ip_address.$error">This information is required</span>
            </v-col>

            <v-col cols="12" v-if="user.device_category == 'attendance_general'">
              <v-text-field
                outlined
                dense
                v-model="user.serial_no"
                :error="$v.user.serial_no.$error"
              >
                <template
                  v-slot:label
                >
                 Serial number <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.user.serial_no.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="!edit">
              <v-text-field @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"  :type="showPassword ? 'text' : 'password'" outlined dense v-model="user.password">
                <template v-slot:label>
                  Password
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>

              <span class="text-danger"  v-if="!$v.user.password.minLength">
                Password must have at least 6 characters.
              </span>
              <span class="text-danger"  v-if="$v.user.password.$error">
               This information is required.
              </span>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="!edit">
              <v-text-field @click:append="showConfirmPassword = !showConfirmPassword" :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"  :type="showConfirmPassword ? 'text' : 'password'" outlined dense v-model="user.password_confirmation">
                <template v-slot:label>
                  Confirm password
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span
                  class="text-danger"
                  v-if="$v.user.password_confirmation && !$v.user.password_confirmation.sameAsPassword"
              >
                Passwords not matching yet
              </span>
            </v-col>
<!--            <div class="col-12">
              <v-textarea
                  name
                  outlined
                  dense
                  v-model="user.description"
                  placeholder="Description"
              ></v-textarea>
            </div>-->
            <v-col cols="12" sm="12" md="4">
              <span class="font-weight-medium subtitle-1">Status</span>
              <v-switch
                  v-model="user.is_active"

                  :label="user.is_active? 'Active':'Inactive'"
                  hide-details
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="btn btn-standard cancel-btn"
            depressed
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="text-white ml-2 btn btn-primary"
            depressed

            :loading="loading"
            @click="createOrUpdate()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>
<script>
import { required, requiredIf, minLength, sameAs,email} from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
const userService = new UserService();
export default {
  validations:{
    user : {
      first_name:{required},
      last_name:{required},
      email: {
        required: requiredIf(function (){
          return this.edit == 0;
        }),email},
      password: {
        minLength: minLength(6),
        required: requiredIf(function (){
          return this.edit == 0;
        }),
      },
      device_category: {required},
      ip_address: { required: requiredIf(function () {
          return this.user.device_category == 'attendance_general';
        })
      },
      serial_no: { required: requiredIf(function () {
          return this.user.device_category == 'attendance_general';
        })
      },
      password_confirmation: {sameAsPassword: sameAs('password')},
    }
  },
  data(){
    return{
      dialog:false,
      loading:false,
      edit:false,
      user:{
        email: null,
        first_name: '',
        last_name: '',
        ip_address: '',
        serial_no: '',
        is_kiosk: 1,
        is_login_verified: 1,
        description: null,
        is_active: true,
        password: null,
        type: 'kiosk',
        account_type: 'kiosk',
        device_category: ''
      },
      showPassword: false,
      showConfirmPassword: false,
      deviceCategories:[
        {
          name: 'Attendance general',
          value: 'attendance_general'
        },
        {
          name: 'Attendance lunch',
          value: 'attendance_lunch'
        },
        {
          name: 'Bus Attendance',
          value: 'attendance_bus'
        },
        {
          name: 'Visitor',
          value: 'visitor'
        },
        {
          name: 'Event',
          value: 'event'
        },
      ]
    }
  },
  methods:{
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
      this.resetData();
    },
    resetData(){
      this.$v.$reset();
      this.user={
        email:null,
        first_name:'',
        last_name:'',
        is_kiosk:1,
        description:null,
        is_active: true,
        password:null,
        type: 'kiosk',
        account_type: 'kiosk',
        device_category: ''
      }
    },
    createDevice(){
      this.resetData();
      this.edit=false;
      this.openDialog();
    },
    editDevice(item){
      this.user=item;
      this.edit=true;
      this.openDialog();
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create(this.user);
        else this.update(this.user);
      }
    },
    _create: function (data) {
      this.loading = true;
      userService
          .createDevice(data)
          .then(response => {
            this.$snotify.success("Devices added");

            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    update(data) {
      this.loading = true;
      delete this.user.email;
      delete this.user.phone;
      userService
          .updateUser(data.id, data)
          .then(response => {
            this.closeDialog();
            this.$snotify.success("Device record updated");
            this.$emit('refresh');
          })
          .catch((err) => {

          })
          .finally(()=>{
            this.loading=false;
          });
    }
  }
}
</script>