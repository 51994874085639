<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>  Manage  KIOSK devices</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \  KIOSK devices
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('user', 'create')" @click="addDevice()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add  KIOSK devices
                  </v-btn>&nbsp;&nbsp;

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                      class="form-control"
                      v-model="search.name"
                      label="Device name,Location"
                      outlined
                      clearable   v-on:keyup.enter="getAllDevices()"
                      dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select label="Status" dense outlined v-model="search.is_active" :items="[{text:'Active',value:1},{text:'Inactive',value:0}]"
                  item-text="text" item-value="value" ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-btn
                      :loading="loading"
                      @click.prevent="getAllDevices()"
                      class="btn btn-primary w-35 float-right"
                      dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column">Device name</th>
                  <th class="px-3 wrap-column">More info</th>
                  <th class="px-3 wrap-column">Status</th>
                  <th class="px-3 text-center">Action</th>
                </tr>
                </thead>
                <template v-if="devices.length > 0">
                  <tr v-for="(item,index) in devices" :key="index">
                    <td class="px-3 wrap-column">
                     <span href="#!"  class="font-weight-medium">
                          {{item.first_name }}
                        </span>
                    </td>
                    
                    <td class="px-3 wrap-column">
                      <div>
                        <strong>Email: </strong>
                        <a class="text-info mb-1" :href="'mailto:'+item.email">
                          {{item.email ? item.email : 'NA' }}
                        </a>
                      </div>

                      <div class="mt-2">
                        <strong>Location</strong>
                        {{item.last_name }}
                      </div>

                      <div class="mt-2">
                        <strong>Category: </strong>
                        {{ item.device_category_formatted ? item.device_category_formatted : '-' }}
                      </div>
                    </td>

                    <td class="px-3 wrap-column">
                      <div class="mt-2">
                          <span class="badge"
                                v-bind:class="{ 'badge-success': item.is_online, 'badge-danger': !item.is_online }">
                            {{ item.is_online ? 'Online' : 'Offline' }}
                          </span>
                      </div>
                      <div class="mt-2">
                          <span class="badge"
                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                            {{ item.is_active ? 'Active' : 'Inactive' }}
                          </span>
                      </div>
                    </td>
                    
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="editDevice(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text  v-if="checkIsAccessible('user','delete')" tag="div" class="navi-item">
                              <a class="navi-link" @click="deleteDevice(item.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="3" class="text-center">
                      <strong>No data available to display.</strong>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
      <create-or-update ref="create-and-update" @refresh="getAllDevices"></create-or-update>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
const userService=new UserService();
import CreateOrUpdate from "@/view/pages/device/CreateOrUpdate.vue";
export default {
  components: {
    CreateOrUpdate
  },
  data(){
    return{
      loading:false,
      dialog: false,
      search:{
        name:'',
        is_active: '',
      },
      devices:[],
      page:'',
      total:'',
      perPage:'',
    }
  },
  methods:{
    addDevice(){
      this.$refs['create-and-update'].createDevice();
    },
    editDevice(item){
      this.$refs['create-and-update'].editDevice(item);
    },
    getAllDevices(){
      this.loading=true;
      userService
          .getAllDevice(this.search)
          .then(response => {
            this.devices=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(()=>{
            this.loading=false;
          });
    },
    deleteDevice(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
                .delete(id)
                .then(response => {
                  this.$snotify.success("Information deleted");
                  this.getAllDevices();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
  },
  mounted() {
    this.getAllDevices();
  }

}
</script>